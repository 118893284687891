#app-container {
  & {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  #crown {
    background-image: linear-gradient(
      to right,
      lightseagreen,
      green,
      orange,
      plum,
      lightcoral,
      maroon
    );
    height: 0.45em;
    display: flex;
    flex: 0 0 auto;
  }

  .header-link {
    color: azure;
    font-size: 1.25rem;
    margin: auto 0.8rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }

  #everything-else {
    & {
      flex: 1 0 auto;
    }

    .type-buttons {
      display: flex;
      justify-content: center;
      flex-flow: wrap;
    }

    .event-admin-config * {
      font-size: 1.1rem;
      font-style: italic;
      font-weight: 750;
    }
  }

  #page_footer {
    & {
      flex-shrink: 0;
      text-align: center;
      background-color: #343a40 !important;
      color: #fff;
    }

    .footer-link {
      color: azure;
      font-size: 1.1rem;
      margin: auto 0.8rem;
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    }
  }
}

// #title {
//   position: relative;
//   color: white;
//   z-index: 5;
// }

// #r51 {
//   border-radius: 0px;
//   background: #333356;
//   z-index: 0;
// }
